body {
  margin: 0;
  font-family: "Roboto";
}
.login-page{
  width: 100%;
  height: 100vh;
}
.login-page .content-div{
  width: 500px;
  height: 600px;
  margin: auto;
  /* border: 1px solid black; */
  padding: 0px 40px;
  padding-top: 15vh;
}
.login-page .logo{
  width: 200px;
}
.title-btns{
  background-color: transparent;
  border: none;
  padding: 0;
}
.w-50px{
  width: 50px;
} 
.w-100-50{
  width: calc(100% - 50px);
}
.login-btn{
  width: 100%;
  background:  #303778;
  padding: 10px 0px;
  font-family: "Roboto";
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  color: #fbfbfb;
  border: none;
}
.form-control{
  border-color: #e3e3e3;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  background: #fbfbfb;
  padding: 10px 13px;
}

.login-t1{
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  color: #303778;
  margin-top: 6px;
  cursor: pointer;
}
.forget-t1{
  font-family: "Roboto";
  font-size: 32px;
  font-weight: 500;
  font-style: normal;
  text-align: center;
  color: #333333;
}
.forget-t2{
  font-size: 20px;
  /* font-weight: bold; */
  font-style: normal;
  text-align: center;
  color: #333333;
}
.margin-auto{
  margin: auto;
}
.user{
  width: 100%;
  min-height: 100vh;
}







/* sidebar css starts  */

.sidebar-wrapper{
  position: fixed;
  height: 100vh;
  width: 285px;
}

.sidebar{
  height: 100vh;
  width: 285px;
  /* border-right: 1px solid black; */
  padding: 10px 10px;
  padding-top: 22px;
  position: relative;
}
.sidbar-ul{
  list-style: none;
  padding-left: 0px;
  margin-top: 15px;
}
.sidbar-ul li{
  width: 100%;
  padding: 10px 12px;
  margin-top: 5px;
  cursor: pointer;
}
.sidbar-ul li b{
  display: inline-block;
  width: 44px;
}
.sidbar-ul li b svg{
  margin-top: -4px;
}
.sidbar-ul li span{
  font-family: "Roboto";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #7c7f84;
  display: inline-block;
  margin-top: 10px;
}
/* sidebar css ends */

.sidbar-ul li:hover{
  border-radius: 5px;
  background: #f5f8fe;

  /* color: #303778; */
}
.sidbar-ul li b svg{
  fill: #7c7f84;
}
.sidbar-ul li:hover span{
  color: #303778;
}
.sidbar-ul li:hover svg{
  fill: #303778;
}

.sidebar .sidbar-ul .active{
  border-radius: 5px!important;
  background: #f5f8fe!important;
  /* color: #303778; */
}
.sidebar .sidbar-ul .active b svg{
  fill: #7c7f84!important;
}
.sidebar .sidbar-ul .active span{
  color: #303778!important;
}
.sidebar .sidbar-ul .active b svg{
  fill: #303778!important;
}

.sidbar-bottom-div{
  position: absolute;
  bottom: 0px;
  width: calc(100% - 20px);
}


.main-content{
  width: calc(100% - 285px);
  margin-left: 285px;

}



/* overview page css starts  */

.overview{
  padding: 18px 24px;
  padding-top: 52px;
  background: #FCFDFF;
}

.overview-t1{
  font-family: "Roboto";
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  text-align: left;
  color: #020524;
  border-bottom: 1px solid #dededf;
  padding-bottom: 45px;
}



/* overview page css ends  */




.overview{
  width: 100%;

}
.pie-title{
  width: 120px;
  text-align: center;
  /* position: relative;
  margin-top: -330px;
  margin-left: 230px; */
  margin-top: -350px;
}
.pie-title{
  font-family: "Roboto";
  font-size: 26px;
  font-weight: 500;
  font-style: normal;
  text-align: center;
  color: #303778;
}

.overview-card{
  border-radius: 20px;
  /* filter: drop-shadow(0px 3px 3px rgba(0,0,0,0.05 )); */
  background: #303778;
  padding: 27px 24px;
  width: 100%;
  height: 275px;
  /* background-size: 100% 260px; */
}
.w-70{
  width: 70%;
}
.w-30{
  width: 30%;
}
.overview-t2{
  /* padding-top: 20px;
  margin-left: 22px; */
  font-family: "Roboto";
  font-size: 26px;
  font-weight: 700;
  font-style: normal;
  text-align: left;
  color: #ffffff;
}
.overview-t2 svg{
  margin-right: 21px;
  position: relative;
  top: -2px;
}
.overview-t2 span{
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  color: #ffffff;
  float: right;
  position: relative;
  top: 2px;
}
.overview-t3{  
  font-family: "Roboto";
  font-size: 26px;
  font-weight: bold;
  font-style: normal;
  text-align: left;
  color: #ffffff;
  /* margin-top: 57px; */
}
.overview-t4{
  font-family: "Roboto";
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  text-align: left;
  color: #ffffff;
}
.mt-38{
  margin-top: 38px;
}
.card-left-div{
  background-image: url(asserts/card2.png);
  height: 257px;
  background-size: 100% 261px;
  text-align: right;
}
.overview-t5{
  font-family: "Roboto";
  font-size: 33px;
  font-weight: bold;
  font-style: normal;
  text-align: right;
  color: #ffffff;
  margin-top: 90px;
  padding-right: 22px;
}
.overview-t6{
  padding-right: 22px;
  margin-top: 55px;
}
.overview-t6 span{
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  text-align: right;
  color: #ffffff;
  margin-right: 15px;
}
.overview-t6{
  font-family: "Roboto";
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  color: #020524;
}
.overview-table-div{
  background: #f5f8fe;
  border-radius: 10px;
  padding: 22px 24px;
}
.overview-t7{
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  text-align: left;
  color: #303778;
}
.overview-t7 span{
  float: right;
}
.w-200px{
  width: 200px;
}
.w-50-200px{
  width: calc(50% - 200px);
}
.overview-t7 svg{
  margin-right: 15px;
}
.active-status{
  font-family: "Roboto";
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  color: #42c78f;
}
.inactive-status{
  font-family: "Roboto";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  color: #ee3f5f;
}
.overview-table-div .rdt_TableRow, .overview-table-div .rdt_TableHeadRow{
  background: #f5f8fe;
}
.overview-table-div2 .rdt_TableHeadRow{
  background: #f5f8fe;
}
.overview-t8{
  font-family: "Roboto";
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  text-align: left;
  color: #020524;
}
.overview-t8 span{
  font-family: "Roboto";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  text-align: right;
  color: #303778;
  float: right;
}






/* overviewo page css ends here  */

















/* staff list css start here  */



.stafflist-page{
  padding: 18px 24px;
  padding-top: 52px;
  background: #FCFDFF;
}
.stafflist-t1{
  font-family: "Roboto";
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  text-align: left;
  color: #020524;
}
.btn-1{
  border-radius: 5px;
  padding: 8px 12px;
  font-family: "Roboto";
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #ffffff;
  background: #303778;
  outline: none;
  border: none;
}

.stafflist-t2{
  font-family: "Roboto";
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  text-align: left;
  color: #020524;
}
.p-relative{
  position: relative;
}
.stafflist-page .search-icon{
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 111;
}
.pointer{
  cursor: pointer;
}
.staff-table-div .rdt_TableHeadRow{
  background: #f5f8fe;
}

.btn-4{
  font-family: "Roboto";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  color: #7c7f84;
  border-color: #dededf;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  background: #f5f8fe;
  padding: 8px 0px;
  width: 160px;
}
.h-45{
  height: 53px!important;
}
.eye-icon-gen{
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
}
.copy-icon{
  position: absolute;
  right: 54px;
  top: 13px;
  cursor: pointer;
}

.w-110{
  width: 110px;
  /* background: #303778; */
}
.hover{
  cursor: pointer;
}

/* staff list css ends here  */



















/* staff detail css starts here  */


.staff-detail-page{
  padding: 18px 24px;
  padding-top: 52px;
  background: #FCFDFF;
}
.staffdetail-t1{
  font-family: "Roboto";
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  text-align: left;
  color: #020524;
}
.staffdetail-t2{
  font-family: "Roboto";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #303778;
  margin-top: 19px;
  padding-bottom: 19px;
  border-bottom: 1px solid #dededf;
}
.greater-than{
  color: #303778;
  font-size: 18px;
  margin: 0px 14px;
}
.staffdetail-t3{
  font-family: "Roboto";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #7c7f84;
}
.staffdetail-sub-heading{
  font-family: "Roboto";
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  text-align: left;
  color: #020524;
}
.staff-detail-page .div1{
  width: 250px;
}
.staff-detail-page .div1 p{
  font-family: "Roboto";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #7c7f84;
  height: 45px;
}
.staff-detail-page .div2 p{
  font-family: "Roboto";
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  text-align: left;
  color: #7c7f84;
  height: 45px;
}
.staff-detail-page .div3{
  width: calc(100% - 250px);
}
.staff-detail-page .div3 p{
  font-family: "Roboto";
  font-size: 18px;
  font-style: normal;
  text-align: left;
  color: #7c7f84;
  height: 45px;
}

.edit-profile .div1 p {
  height: 54px;
}
.edit-profile .div2 p {
  height: 54px;
}
.edit-profile .div2 p input{
  margin-top: -1px;
}
.text-bold{
  font-weight: bold;
}
.color-42c78f{
  color: #42c78f!important;
}
.btn-2{
  border: 1px solid #303778;
  border-radius: 5px;
  background: #ffffff;
  padding: 7px 5px;
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  color: #303778;
  width: 120px;
}
.btn-3{
  border: 1px solid #303778;
  border-radius: 5px;
  background: #303778;
  padding: 7px 5px;
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  color: #ffffff;
  width: 120px;
}
.btn-34{
  border: 1px solid #303778;
  border-radius: 5px;
  background: #303778;
  padding: 7px 5px;
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  color: #ffffff;
  width: 150px;
}





/* staff detail css ends here  */















/* switch button css starts here  */


/* swith button css  */

.switch-btn-div input{
  background: #FAFAFA;
  border: 1px solid #E0E0E0;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 128px;
}

input[type="checkbox"] {
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}

.toggle-station {
  height: 40px;
  width: 116px;
  border-radius: 146px;
  display: block;
  position: relative;
  margin: 0;
  /* background-color: gainsboro; */
  background-color: #CCCCCC;
  transition: all .2s ease;
  margin-right: 8px;
}

.toggle-station:checked {
  background: #40AD61;
}

.toggle-station:after {
  content: '';
  position: absolute;
  top: 2px;
  left: 79px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background: white;
  box-shadow: 0 1px 2px rgba(44,44,44,.2);
  transition: all .2s cubic-bezier(.5,.1,.75,1.35);
}

.toggle-station:checked:after {
  left: -17px;
  transform: translatex(20px);
}
.switch-active-label{
  margin-top: -40px;
  display: inline-block;
  position: relative;
  top: -33px;
  font-family: "Roboto";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  color: #ffffff;
  left: 45px;
}

.swith-off{
  left: 13px;
}
.toggle-station:checked .switch-active-label{
  color: #020524;
}

.pdf-table-print{
  border: 1px solid black;
  width: 100%;
}
.pdf-table-print tr{
  border-bottom: 1px solid black;
}
.pointer{
  cursor: pointer;
}






/* switch button css ends  */


.addstaff-t1{
  font-family: "Roboto";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #7c7f84;
}
.setting-t1{
  font-family: "Roboto";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #303778;
  margin-left: 25px;
}
.setting-t1 svg{
  position: relative;
  top: -1.5px;
  right: 6px;
}

.text-normal{
  font-weight: normal!important;
}

.w-40{
  width: 40%;
}


.p-relative{
  position: relative;
}
.eye-iconn{
  position: absolute;
  top: 11px;
  right: 10px;
  cursor: pointer;
}

.generate-pass-btn{
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  color: #7c7f84;
  border-color: #dededf;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  background: #f5f8fe;
  width: 150px;
  padding: 8.5px 1px;
}
.fundrequest-t1{
  border-bottom: 1px solid #dededf;
  padding-bottom: 24px;
}
.thb-label{
  font-family: "Roboto";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #7c7f84;
  display: inline-block;
  margin-left: 8px;
}
.w-60{
  width: 60%;
}
.transfer-t1{
  font-family: "Roboto";
  font-size: 18px;
  font-weight: normal!important;
  font-style: normal;
  text-align: left;
  color: #7c7f84;
  margin-top: -1px;
  height: auto!important;
}

input[type='radio'] {
  accent-color: #303778;
}
.pointer{
  cursor: pointer;
}
.without-bg-select{
  background: none!important;
  border: none!important;
}
.without-bg-select:focus{
  background: none!important;
  border: none!important;
}
.card-success-t1{
  font-family: "Roboto";
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  text-align: left;
  color: #42c78f;
}

.report-t1{
  font-family: "Roboto";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  color: #7c7f84;
}
.react-datepicker-div{
  position: relative;
}
.react-datepicker-div .react-datepickerr{
  border: 1px solid #dededf;
  border-radius: 5px;
  background: #f5f8fe;
  padding: 8px 12px;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #707070;
  text-align: center;
  padding-left: 30px;
  width: 180px;
}
.react-datepicker-div svg{
  position: absolute;
  left: 12px;
  top: 8px;
  /* z-index: 3; */
}
.report-heading{
  border-bottom: 1px solid #dededf;
  padding-bottom: 24px;
}
.btn-5{
  border-radius: 5px;
  background: #dededf;
  border: none;
}
.model-type2{
  padding: 30px 60px;
}
.model-type2 .p1 span{
  font-family: "Roboto";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #e7554b;
  float: right;
}
.model-type2 .label-1{
  font-family: "Roboto";
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  text-align: left;
  color: #757575;
}

.bg-e7554b{
  background: #e7554b!important;
}
.model-type2 h1{
  font-family: "Roboto";
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  text-align: left;
  color: #293142;
  border-bottom: 1px solid #dededf;
  padding-bottom: 20px;
}
.model-type2 .box-div{
  border-color: #e9ebf1;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  background: #ffffff;
  width: 100%;
  padding: 20px 22px;
}
.model-type2 .p2{
  font-family: "Roboto";
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  text-align: left;
  color: #293142;
}
.model-type2 .p3{
  font-family: "Roboto";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #293142;
  line-height: 11px;
}
.model-type2 .p4{
  font-family: "Roboto";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #e7554b;
}

.model-type2 .save-btn{
  border-radius: 50px;
  background: #cccccc;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #ffffff;
  padding: 6px 22px;
  border: none;


}

.verify-email-div{
  padding: 50px 130px;
}
.verify-email-div h1{
  font-family: "Roboto";
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  text-align: center;
  color: #020524;
  border-bottom: 1px solid #dededf;
  padding-bottom: 30px;
}

.verify-email-div p{
  font-family: "Roboto";
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  color: #7c7f84;

}
.verify-email-div h3{
  font-family: "Roboto";
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  text-align: left;
  color: #020524;
}
.bdr-t{
  border-top: 1px solid #707070;
  position: relative;
  top: 13px;
}

.loaderr{
  width: 100%;
  padding-left: calc(50% - 25px);
  padding-top: 34vh;
}

.invoice-header{
  border-bottom: 3px solid #303778;
}
.clr-303778{
  color: #303778;
}
.pdf-table1{
  width: 100%;
}
.pdf-table1 tr{
  background-color: rgb(237, 237, 237);
  border-bottom: 2px solid #f5f5f5;
}
.pdf-table1 tr td, .pdf-table1 tr th{
  height: 40px;
  padding: 4px 10px;
}
.w-60px{
  width: 60px;
}
.w-35{
  width: 35%;
}
.w-65{
  width: 65%;
}
.w-100px{
  width: 100px;
}
.bg-303778{
  background-color: #303778;
  color: #fff;
}
.pdf-table2{
  width: 100%;
}
.pdf-table2 tr th{
  height: 40px;
  padding: 4px 3px;
}
.pdf-table2 .bb-rgb-237{
  border-bottom: 2px solid rgb(237, 237, 237);
}
.pdf-table2 .bb-303778{
  border-bottom: 2px solid #303778;
}
.login-heading{
  color: #303778;
  font-weight: 900;
}
.login-sub-heading{
  color: #303778;
  font-size: 22px;
  font-weight: 600;
}